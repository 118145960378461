.category-screen {
  .category-screen__breadcrumbs {
    p {
      font-size: 0.875rem;
      color: #008080;
    }
  }

  .slick-track {
    margin-left: unset;
  }

  .category-screen__brand {
    margin: 30px 0;
    .category-screen__brand__title {
      position: relative;
      p {
        font-size: 1.875rem;
        font-weight: bold;
        line-height: 41px;
        border-bottom: 1px solid #d2cbcb;
        padding-bottom: 21px;
      }
      p::after {
        width: 38%;
        position: absolute;
        bottom: 0;
        left: 0;
        height: 3px;
        background-color: #008081;
        content: '';
      }
    }
    .category-screen__brand__list {
      .category-screen__brand__list__item {
        width: 90%;
        margin: 0 auto;
        .slider-item {
          a {
            outline: 0;
          }
          img {
            width: 217px;
            height: 136px;
            margin: 0 auto;
          }
        }
      }
      position: relative;
      button {
        cursor: pointer;
        border: none;
        background-color: transparent;
        outline: 0;
        position: absolute;
        top: 38%;
      }
      .promotions__btn-carousel-left {
        left: 0;
      }
      .promotions__btn-carousel-right {
        right: 0;
      }
    }
    .category-screen__brand__banner {
      img {
        height: 120px;
        width: 100%;
      }
    }
  }
  .category-screen__main {
    margin-bottom: 30px;
    .category-screen__main__title {
      p {
        font-size: 1.875rem;
        font-weight: bold;
        line-height: 41px;
        margin-bottom: 20px;
      }
    }
    .category-screen__main__content {
      display: flex;
      .category-screen__main__content__sidebar {
        display: flex;
        width: 20%;
        flex-direction: column;
        @media screen and (max-width: 1024px) {
          width: 30%;
        }
        .category-screen__main__content__sidebar__title {
          font-size: 1.25rem;
          font-weight: bold;
          color: #000000;
          @media screen and (max-width: 1024px) {
            font-size: 1rem;
          }
        }
        .border-bottom {
          border-bottom: 1px solid #d2cbcb;
        }
        .list-unstyled {
          #home-collapse {
            margin-top: 30px;
          }
          li {
            button {
              display: flex;
              outline: 0;
              box-shadow: none;
              padding: 5px 5px 5px 0;
              height: 40px;
              img {
                width: 33px;
                height: 29px;
              }
              p {
                color: #000000;
                font-weight: 600;
                font-size: 1.25rem;
                margin-left: 5px;

                @media screen and (max-width: 1024px) {
                  font-size: 1rem;
                }
              }
            }
            ul {
              font-size: 1rem;
              margin-top: 22px;
              li {
                height: 40px;
                margin: 8px 0;
                &.active {
                  font-weight: bold;
                  a {
                    color: #008080 !important;
                  }
                }
                a {
                  padding: 10px 0;
                  text-decoration: none;
                }
                &.preview {
                  a {
                    color: #20abad !important;
                  }
                }
              }
            }
          }
        }
        .category-screen__main__content__sidebar__price {
          border-bottom: 1px solid #d2cbcb;
          margin: 15px 0;
          padding-bottom: 20px;
          li {
            display: flex;
            align-items: center;
            height: 40px;
            label {
              font-size: 14px;
            }
            .form-check-input {
              background-color: #ffffff;
              margin-top: 0;
              width: 20px;
              height: 20px;

              &:checked {
                background-image: url('../icons/ic_checked.png');
                background-repeat: no-repeat;
                background-size: 14px 14px;
                border: 1px solid #fb800f;
              }
            }
          }
        }
        .category-screen__main__content__sidebar__review {
          border-bottom: 1px solid #d2cbcb;
          margin: 15px 0;
          padding-bottom: 20px;
          li {
            display: flex;
            align-items: center;
            height: 40px;
            cursor: pointer;
            &.active {
              background-color: antiquewhite;
            }
          }
        }
        .category-screen__main__content__sidebar__service {
          margin: 15px 0;
          padding-bottom: 20px;
          li {
            display: flex;
            align-items: center;
            height: 40px;
            .form-check-input {
              width: 20px;
              height: 20px;
              border-radius: 50%;
              margin-top: 0;
              &:checked {
                background-color: #fb800f;
                border: 1px solid #fb800f;
              }
            }
          }
        }
        .category-screen__main__content__sidebar__btn {
          button {
            width: 100%;
            background-color: #f15b2e;
            border-radius: 10px;
            border: none;
            outline: 0;
            color: #ffffff;
            padding: 13px 0;
          }
        }
      }
      .category-screen__main__content__products {
        width: 80%;
        .category-screen__main__content__products__top {
          display: flex;
          @media screen and (max-width: 1365px) {
            flex-wrap: wrap;
          }
          .search-screen__more-filter__left__select {
            display: flex;
            width: 50%;
            align-items: center;
            @media screen and (max-width: 1440px) {
              width: 60%;
            }
            @media screen and (max-width: 1024px) {
              width: 65%;
            }
            @media screen and (max-width: 820px) {
              width: 100%;
            }
            p {
              width: 40%;
            }
            .form-select {
              margin: 0 20px;
            }
          }
          .search-screen__more-filter__right {
            display: flex;
            align-items: center;
            margin-left: auto;
            @media screen and (max-width: 820px) {
              margin-top: 10px;
              margin-left: 80px;
            }
            .form-check-input {
              width: 20px;
              height: 20px;
              border-radius: 50%;
              margin-top: 0;
              &:checked {
                background-color: #fb800f;
                border: 1px solid #fb800f;
              }
            }
          }
        }
        .category-screen__main__content__products__list {
          display: flex;
          flex-wrap: wrap;
          .category-screen__main__content__products__item {
            width: 25%;
            @media screen and (max-width: 920px) {
              width: 33%;
            }
            @media screen and (max-width: 820px) {
              width: 50%;
            }
          }
        }
      }
    }
  }
}
