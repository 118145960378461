@import 'var';
.clearfix {
  clear: both;
}
.menu-header {
  width: 100%;
  height: 48px;
  background-color: #ffffff;
  border-bottom: 1px solid $baseColorStrong;
  display: flex;

  .menu-header__main-menu {
    display: flex;
    height: 100%;
    max-width: 1611px;
    margin: 0 auto;

    @media screen and (min-width: 1440px) {
      width: 85% !important;
    }

    @media screen and (max-width: 1611px) {
      padding: 25px 15px !important;
      width: 100%;
    }

    .main-menu__ul {
      display: inherit;
      flex: 3;
      width: 100%;
      justify-content: space-between;

      .main-menu__li {
        display: flex;
        flex: 1;

        a,
        div {
          font-size: 1rem;
          font-weight: bold;
          color: #3f3b3b;
          line-height: 48px;
          display: flex;
          align-items: center;
          position: relative;
          padding-left: 10px;
          cursor: pointer;

          &:first-child {
            padding-left: 0;
          }

          img {
            margin-left: 5px;
            width: 9px;
            height: 6px;
          }
          .arrow-menu-next {
            width: 8px;
            height: 11px;
          }
          .arrow-menu-down {
            width: 10px;
            height: 7px;
          }

          &:hover {
            .sub-menu {
              visibility: visible;
              opacity: 1;
              display: flex;
              flex-direction: column;
            }
            .arrow-menu-down {
              display: flex;
            }
            .arrow-menu-next {
              display: none;
            }
          }

          @media screen and (max-width: 820px) {
            font-size: 13px;
          }
          @media screen and (max-width: 768px) {
            font-size: 12px;
          }
        }
        .sub-menu {
          position: absolute;
          background-color: #ffffff;
          width: 190px;
          border-radius: 5px;
          display: none;
          visibility: hidden;
          opacity: 0;
          transition: all 0.5s ease;
          top: 45px;

          @media screen and (max-width: 1600px) {
            top: 22px;
          }

          li {
            background-color: #ffffff;
            border-bottom: 1px solid #e0e0e0;
            z-index: 999999;

            a {
              padding-left: 25px;
              line-height: 40px;
              font-size: 14px;
              color: #3f3b3b;
              font-weight: normal;
            }

            &:hover {
              background-color: #d4f4f4;
            }
          }
        }
        .arrow-menu-down {
          display: none;
        }
      }
    }
    .menu-header__right {
      display: flex;
      right: 0;
      .menu-header__phone-group {
        display: inherit;
        height: 100%;
        align-items: center;
        margin-right: 6px;

        .menu-header__phone-group__btn {
          border: 2px solid $baseColorStrong;
          display: flex;
          align-items: center;
          border-radius: 5px;
          height: 85%;
          min-width: 194px;
          padding: 7px 10px;

          .menu-header__phone-group__text {
            display: flex;
            flex-direction: column;
            margin-left: 11px;

            p {
              width: 100%;
              font-size: 10px;
              color: #3f3b3b;
            }

            .menu-header__phone-group__phone {
              color: $baseColorStrong;
              font-size: 0.875rem;
            }
          }
        }
      }
      .menu-header__prescription {
        display: inherit;
        height: 100%;
        align-items: center;
        .menu-header__prescription__btn {
          display: flex;
          align-items: center;
          padding: 7px 10px;
          background-color: $baseColorStrong;
          border-radius: 5px;
          height: 85%;
          min-width: 194px;

          .menu-header__prescription__text {
            font-size: 14px;
            color: #ffffff;
            margin-left: 11px;
          }
        }
      }
    }
  }
}

.outline-0 {
  outline: none;
}

//Responsive
//@media screen and (max-width: 1366px) {
//    .menu-header {
//        .menu-header__main-menu {
//            .main-menu__ul {
//                .main-menu__li {
//                    padding-right: 15%;
//                }
//            }
//        }
//    }
//}
//@media screen and (max-width: 1080px) {
//    .menu-header {
//        .menu-header__main-menu {
//            .main-menu__ul {
//                .main-menu__li {
//                    padding-right: 12%;
//                }
//            }
//        }
//    }
//}
