@import 'var';

.main-layout__cart {
  position: relative;
  transition: 0.5s all;
  width: 230px;
  background: #ffffff;
  box-shadow: -2px 4px 4px rgba(0, 0, 0, 0.1);
  display: none;

  &.show {
    display: block;
  }
}

.check-radio {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  width: 22px;
  height: 22px;
  border-radius: 22px;
  border: 1px solid #000000;
  cursor: pointer;

  svg {
    font-size: 10px;
    path {
      fill: $whiteColor;
    }
  }

  &.active,
  &:hover {
    background: $orangeColor;
    border: 1px solid $orangeColor;
  }
}

.fast-cart-arrow {
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-right: 20px solid $whiteColor;
  position: fixed;
  top: 70px;
  right: 230px;
  z-index: 100;
  display: none;

  &.show {
    display: block;
  }
}

.fast-cart {
  position: fixed;
  top: 0;
  background: $whiteColor;
  height: 100vh;
  width: 230px;
  padding: 20px 10px 50px 10px;
  overflow-y: auto;
  z-index: 999;
}

.fast-cart__total {
  font-size: 16px;
  line-height: 22px;
  color: $blackColor;
  margin-bottom: 10px;

  span {
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    padding-left: 5px;
  }
}

.fast-cart__ship-fee {
  font-size: 14px;
  line-height: 19px;
  color: #07844f;
  margin-bottom: 10px;

  &--hide {
    display: none;
    margin-bottom: 0;

    img {
      width: 20px;
    }
  }

  span {
    padding-left: 5px;
    font-weight: bold;
  }
}

.fast-cart__promotion {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  p {
    font-size: 14px;
    line-height: 14px;
    padding-left: 5px;
  }

  .check-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15px;
    height: 15px;
    border-radius: 10px;
  }

  &--checked {
    p {
      color: #27ae60;
      font-weight: bold;
    }

    svg {
      font-size: 9px;
      path {
        fill: #27ae60;
      }
    }

    .check-wrap {
      background: #daffe2;
      border: 1px solid #27ae60;
    }
  }

  &--normal {
    p {
      color: #828282;
    }

    svg {
      display: none;
    }

    .check-wrap {
      border: 1px solid #828282;
    }
  }
}

.fast-cart__suggest {
  margin: 0 -10px;
  padding: 5px 10px;
  background: #ffe3c9;

  p {
    font-size: 12px;
    line-height: 16px;
    color: $blackColor;
  }

  span {
    font-weight: 600;
    color: $baseColorLight;
  }
}

.fast-cart__btn-checkout {
  display: flex;
  justify-content: center;

  button {
    border: none;
    padding: 5px 30px;
    background: #f0592d;
    border-radius: 30px;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: $whiteColor;
    transition: 0.5s all;
    margin: 15px 0;

    &:hover {
      transform: scale(1.1);
    }
  }
}

.fast-cart__list-item {
  margin: 0 -10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (max-width: 1611px) {
  .main-layout__cart {
    width: 120px;
  }

  .fast-cart-arrow {
    right: 120px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid $whiteColor;
  }

  .fast-cart {
    width: 120px;
  }

  .fast-cart__total {
    font-size: 14px;
    line-height: 19px;

    span {
      font-size: 16px;
      line-height: 22px;
      display: block;
    }
  }

  .fast-cart__ship-fee {
    &--full {
      display: none;
    }

    &--hide {
      display: block;
    }
  }

  .fast-cart__suggest,
  .fast-cart__promotion {
    display: none;
  }

  .fast-cart__btn-checkout button {
    font-size: 12px;
    line-height: 16px;
    padding: 7px 12px;
    margin: 10px 0 15px 0;
  }
}

.fast-cart-item {
  display: flex;
  padding: 0 7.5px 20px 7.5px;
}

.fast-cart-item__image-box {
  position: relative;

  &__regular-icon {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 20px;
    height: 20px;
    object-fit: contain;
  }

  &__delete-btn {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    width: 27px;
    height: 27px;
    object-fit: contain;
    cursor: pointer;
  }

  &__quantity-wrap {
    position: absolute;
    z-index: 10;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    background: $baseColorLight;
    border-radius: 20px;
    padding: 5px 5px;
    cursor: pointer;
    transition: 0.5s all;

    &.active {
      cursor: none;

      button {
        display: flex;
      }

      input {
        width: 35px;
      }
    }

    button {
      display: none;
      align-items: center;
      justify-content: center;
      width: 25px;
      height: 25px;
      border-radius: 15px;
      background: $whiteColor;
      cursor: pointer;
      border: none;
    }

    input {
      width: 20px;
      border: none;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: $whiteColor;
      background: $baseColorLight;
      text-align: center;
      cursor: pointer;

      &:focus {
        outline: none;
      }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type='number'] {
      -moz-appearance: textfield;
    }
  }
}

.fast-cart-item__image-box__image {
  .fast-cart-item-img {
    width: 95px;
    height: 100px;

    img {
      max-width: 100%;
      max-height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  //@media screen and (max-width: 1199px) {
  //	padding: 15px 10px 15px 10px;
  //	height: 180px;
  //}
}

.fast-cart-item__content-box {
  flex: 1;
  padding-left: 10px;

  &__name {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    color: #3f3b3b;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-decoration: none;
    margin-bottom: 3px;
  }

  &__price {
    font-size: 14px;
    line-height: 19px;
    color: $baseColorStrong;
    margin-bottom: 3px;
  }

  &__button {
    border: 1px solid #3f3b3b;
    box-sizing: border-box;
    border-radius: 20px;
    padding: 5px 20px;
    font-size: 12px;
    line-height: 16px;
    color: #3f3b3b;
    background: $whiteColor;
    margin-top: 10px;
  }
}

@media screen and (max-width: 1611px) {
  .fast-cart-item {
    padding: 0 5px 15px 5px;
  }

  .fast-cart-item__content-box {
    display: none;
  }
}

.fast-cart-voucher-item {
  background-image: url('../images/DetailProductScreen/bg_voucher.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
  padding: 10px 10px 10px 8px;
  display: inline-block;
  min-width: 125px;
  max-width: 150px;

  p {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #767474;
  }

  span {
    font-size: 12px;
    line-height: 16px;
    color: #767474;
  }

  &.active {
    background-image: url('../images/DetailProductScreen/bg_voucher_full.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center center;

    p,
    span {
      color: $whiteColor;
    }
  }
}

.fast-cart-checkout-item {
  display: flex;
  margin-bottom: 25px;
}

.fast-cart-checkout-item__image-box {
  width: 80px;
  height: 100px;
  margin-left: 15px;

  img {
    width: 100%;
    max-width: 80px;
    max-height: 100px;
    object-fit: contain;
  }
}

.fast-cart-checkout-item__content-box {
  flex: 1;
  display: flex;
  justify-content: space-between;
  padding-left: 15px;

  .item-content-box-left {
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;

    &__product-name {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #131313;
      margin-bottom: 5px;
    }

    &__variant-name {
      font-size: 16px;
      line-height: 22px;
      color: #767474;
      margin-bottom: 5px;
    }
  }

  .item-content-box-left__regular-label {
    display: flex;
    align-items: center;
    background: $orangeColor;
    border-radius: 5px;
    padding: 1px 5px;
    margin-bottom: 5px;

    p {
      font-size: 10px;
      line-height: 14px;
      color: $whiteColor;
      padding-left: 5px;
    }
  }

  .item-content-box-left__campaign-tag {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    flex-wrap: wrap;

    img {
      margin-right: 10px;
    }
  }

  .item-content-box-left__price-box {
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    &__price-active {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: $baseColorStrong;
    }

    &__discount {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: $orangeColor2;
      border: 1.5px solid $orangeColor2;
      box-sizing: border-box;
      border-radius: 5px;
      padding: 1px 5px;
      margin-left: 10px;
    }

    &__price-origin {
      font-size: 14px;
      line-height: 19px;
      text-decoration-line: line-through;
      color: #767474;
      margin-left: 10px;
    }
  }

  .item-content-box-left__unit-box {
    position: relative;

    &__select-picker {
      font-size: 12px;
      line-height: 16px;
      color: #131313;
      background: #e0e0e0;
      border-radius: 5px;
      border: none;
      padding: 3px 10px;
      margin-bottom: 5px;

      &:focus {
        outline: none;
      }

      option {
        background: #ffffff;
        color: $baseColorLight;
        font-weight: bold;
      }

      option:not(:checked) {
        color: #000000;
        font-weight: 400;
      }
    }

    &__label {
      font-size: 12px;
      line-height: 16px;
      color: #131313;
      background: #e0e0e0;
      border-radius: 5px;
      border: none;
      padding: 3px 10px;
      margin-bottom: 5px;
      cursor: pointer;

      svg {
        padding-left: 5px;
      }
    }
  }

  .item-content-box-left__unit-box__modal-picker {
    position: absolute;
    top: 22px;
    left: 0;
    background: $whiteColor;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 10px 7.5px;
    z-index: 10;
    display: none;
    max-width: 350px;
    min-width: 290px;

    &.show {
      display: block;
    }

    &__btn {
      display: flex;
      justify-content: flex-end;
      padding-top: 10px;
      margin: 5px 7.5px;

      button {
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: $whiteColor;
        padding: 10px 25px;
        background: $orangeColor;
        border-radius: 30px;
        border: none;
      }
    }
  }

  .item-content-box-left__unit-box__modal-picker__list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    &__item {
      background: $whiteColor;
      border: 1.5px solid #767474;
      box-sizing: border-box;
      border-radius: 5px;
      padding: 5px 15px;
      margin: 5px 7.5px;
      max-width: 150px;
      min-width: 120px;
      cursor: pointer;

      p {
        font-size: 14px;
        line-height: 19px;
        color: #767474;
      }

      span {
        font-weight: bold;
        font-size: 18px;
        line-height: 25px;
        color: #767474;
      }

      &.active {
        border: 1.5px solid $baseColorLight;

        p,
        span {
          color: $baseColorStrong;
        }
      }
    }
  }

  .item-content-box-left__cycle-box {
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    p {
      font-size: 12px;
      line-height: 16px;
      color: $blackColor;
    }

    span {
      padding-left: 15px;
      font-weight: 600;
    }

    .item-content-box-left__cycle-box_value {
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: $baseColorStrong;
      border: none;
      margin-left: 15px;

      &:focus {
        outline: none;
      }
    }
  }

  .item-content-box-left__time-box {
    font-size: 12px;
    line-height: 16px;
    color: $blackColor;

    span {
      padding-left: 15px;
      font-weight: 600;
    }
  }

  .item-content-box-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    max-width: 220px;
  }

  .item-content-box-right__total-price {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: $baseColorStrong;
    margin-bottom: 5px;
  }

  .item-content-box-right__quantity-text {
    font-size: 12px;
    line-height: 16px;
    color: $blackColor;

    span {
      padding-left: 5px;
    }
  }

  .item-content-box-right__quantity-wrap {
    display: flex;
    align-items: center;
    background: $baseColorLight;
    border-radius: 20px;
    transition: 0.5s all;
    margin-bottom: 8px;

    .quantity-wrap {
      margin-left: 0;
    }
  }

  .item-content-box-right__regular-note {
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    color: #767474;
    margin-bottom: 5px;
  }

  .item-content-box-right__btn-edit {
    display: flex;
    align-items: center;

    button {
      font-size: 12px;
      line-height: 16px;
      color: #3f3b3b;
      border: 1px solid #767474;
      box-sizing: border-box;
      border-radius: 20px;
      padding: 4px 6px;
      background: $whiteColor;
      min-width: 58px;
      text-align: center;
      margin-left: 10px;
    }
  }

  .item-content-box-right__buy-type {
    position: relative;

    &:hover {
      .item-content-box-right__buy-type__dropdown {
        display: block;
      }

      .item-content-box-right__buy-type__title svg {
        transform: rotate(90deg);
      }
    }
  }

  .item-content-box-right__buy-type__title {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 5px 0;

    p {
      font-size: 12px;
      line-height: 16px;
      text-decoration-line: underline;
      color: $baseColorStrong;
    }

    i {
      font-size: 12px;
      padding-left: 10px;
      color: $baseColorStrong;
    }
  }

  .item-content-box-right__buy-type__dropdown {
    padding: 15px 15px;
    background: $whiteColor;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 25px;
    right: 0;
    width: 220px;
    display: none;
    z-index: 10;

    li {
      font-size: 12px;
      line-height: 16px;
      text-align: justify;
      color: $blackColor;
    }

    li::before {
      content: '\2022'; /* Add content: \2022 is the CSS Code/unicode for a bullet */
      color: $baseColorStrong; /* Change the color */
      font-weight: bold; /* If you want it to be bold */
      display: inline-block; /* Needed to add space between the bullet and the text */
      width: 1em; /* Also needed for space (tweak if needed) */
      margin-left: -1em; /* Also needed for space (tweak if needed) */
    }

    &__confirm-box {
      display: flex;
      align-items: center;
      padding-top: 5px;
      justify-content: space-between;
      margin-bottom: 5px;

      span {
        font-size: 13px;
        line-height: 17px;
        color: $blackColor;
      }

      select {
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        text-decoration-line: underline;
        color: $baseColorStrong;
        border: none;
        margin-left: 5px;

        &:focus {
          outline: none;
        }

        option {
          background: #ffffff;
          color: $baseColorLight;
          font-weight: bold;
        }

        option:not(:checked) {
          color: #000000;
          font-weight: 400;
        }
      }
    }

    &__btn-wrap {
      display: flex;
      align-items: center;

      &__btn-cancel {
        font-size: 12px;
        line-height: 16px;
        color: #3f3b3b;
        border: 1px solid #3f3b3b;
        box-sizing: border-box;
        border-radius: 30px;
        padding: 8px 15px;
        background: $whiteColor;
        text-align: center;
      }

      &__btn-confirm {
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: $whiteColor;
        background: $orangeColor;
        border: 1px solid $orangeColor;
        border-radius: 30px;
        padding: 8px 10px;
        text-align: center;
        flex: 1;
        margin-left: 10px;
      }
    }
  }
}

.fast-cart-gift-item {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  padding-left: 105px;
  margin-top: -15px;
}

// .fast-cart-gift-item__btn-check {
//   // opacity: 0.6;
// }

.fast-cart-gift-item__image-box {
  width: 65px;
  height: 85px;
  margin-left: 15px;
  // opacity: 0.6;

  img {
    width: 100%;
    max-width: 65px;
    max-height: 85px;
    object-fit: contain;
  }
}

.fast-cart-gift-item__content-box {
  flex: 1;
  display: flex;
  justify-content: space-between;
  padding-left: 15px;
}

.gift-item-content-box-left {
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;

  &__product-name {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #131313;
    margin-bottom: 5px;
    // opacity: 0.6;
  }

  &__variant-name {
    font-size: 16px;
    line-height: 22px;
    color: #131313;
    margin-bottom: 5px;
    // opacity: 0.6;
  }

  &__price-origin {
    font-size: 14px;
    line-height: 19px;
    text-decoration-line: line-through;
    color: #767474;
    margin-left: 10px;
  }
}

.gift-item-content-box-left__campaign-tag {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  flex-wrap: wrap;

  img {
    margin-right: 10px;
  }
}

.gift-item-content-box-left__price-box {
  // opacity: 0.6;
  display: flex;
  align-items: center;
  margin-bottom: 5px;

  &__price-active {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: $baseColorStrong;
  }

  &__discount {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: $orangeColor2;
    border: 1.5px solid $orangeColor2;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 1px 5px;
    margin-left: 10px;
  }

  &__price-origin {
    font-size: 14px;
    line-height: 19px;
    text-decoration-line: line-through;
    color: #767474;
    margin-left: 10px;
  }
}

.gift-item-content-box-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  max-width: 100px;
  // opacity: 0.6;
}

.gift-item-content-box-right__total-price {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: $baseColorStrong;
  margin-bottom: 5px;
}

.gift-item-content-box-right__quantity {
  font-size: 12px;
  line-height: 16px;
  color: $blackColor;

  span {
    padding-left: 5px;
  }
}

//.modal-fast-cart {
//	width: 500px;
//	height: 100%;
//	margin:  0 0;
//	float: right;
//}

.modal-fast-cart {
  .modal {
    background: rgba(0, 0, 0, 0.5);
  }

  .modal.fade .modal-dialog {
    transition: transform 0.5s ease-out;
    transform: translate(200px, 0);
  }

  .modal.show .modal-dialog {
    transform: none;
  }

  .modal-dialog {
    margin: 0 0;
    float: right;
    height: 100%;
    width: 800px;
  }
}

.modal-cart-content {
  height: 100vh;
  background: $whiteColor;

  .modal-cart-no-item__container {
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .modal-cart-no-item__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 60px;
      }
      p {
        margin-top: 10px;
        font-size: 24px;
        font-weight: 600;
      }
    }

    .modal-cart-no-item__action {
      margin-top: 12px;

      .modal-cart-no-item__button {
        font-weight: 600;
        padding: 8px 12px;
        background-color: $orangeColor;
        color: $whiteColor;
        border: none;
        border-radius: 12px;
        font-size: 18px;
      }
    }
  }
}

.modal-cart-content__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  background: $baseColorLight;
  border-radius: 7px 7px 0 0;

  h3 {
    font-weight: bold;
    font-size: 22px;
    line-height: 28px;
    color: $whiteColor;
  }

  button {
    font-size: 24px;
    color: $whiteColor;
    border: none;
    background: $baseColorLight;

    &:hover {
      color: $whiteColor;
    }

    svg path {
      fill: $whiteColor;
    }
  }
}

.modal-cart-content__info {
  overflow-y: auto;
  height: 100%;
  margin-bottom: 90px;
  padding-bottom: 30px;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }
}

.modal-cart-content__transfer {
  padding: 10px 20px;
  background: #f4ffff;
}

.modal-cart-content__transfer__milestone {
  display: flex;
  align-items: center;

  .milestone-gift-box {
    display: flex;
    align-items: center;
    flex: 1;
    padding-left: 15px;
    justify-content: flex-end;

    p {
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      color: #07844f;
      padding-left: 10px;
    }
  }

  .milestone-box {
    position: relative;
    width: 400px;
    height: 60px;
    padding: 30px 50px 0 20px;
  }

  .milestone-box__percent {
    width: 100%;
    height: 5px;
    background: #d2cbcb;
    border-radius: 10px;
    overflow: hidden;

    .percent-line {
      height: 100%;
      background: #27ae60;
    }
  }

  .milestone-box__content {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    height: 60px;
    top: 0;
    z-index: 5;

    &--left {
      left: 0;
      width: 40px;
    }

    &--center {
      left: 140px;
      width: 100px;
    }

    &--right {
      right: 0;
      width: 100px;
    }

    &.active {
      .milestone-box__content__price {
        font-weight: bold;
        color: #27ae60;
      }

      .milestone-box__content__check {
        background: #daffe2;
        border: 1px solid #27ae60;

        svg {
          font-size: 9px;
          display: block;
          path {
            fill: #27ae60;
          }
        }
      }
    }
  }

  .milestone-box__content__label {
    font-size: 14px;
    line-height: 19px;
    color: #828282;
    padding-top: 1px;
  }

  .milestone-box__content__price {
    font-size: 14px;
    line-height: 19px;
    color: #828282;
  }

  .milestone-box__content__check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15px;
    height: 15px;
    border-radius: 10px;
    border: 1px solid #828282;
    background: #f4ffff;

    svg {
      display: none;
    }
  }
}

.modal-cart-content__transfer__info {
  h5 {
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    text-transform: uppercase;
    color: $blackColor;
    padding-top: 15px;
  }

  .transfer-row-info {
    display: flex;
    align-items: center;
    padding: 5px 0;
  }

  .transfer-row-info__label {
    font-size: 14px;
    line-height: 19px;
    color: $blackColor;
    width: 100px;
  }

  .transfer-row-info__text {
    font-size: 14px;
    line-height: 19px;
    color: $blackColor;
  }

  .transfer-row-info__input {
    padding: 5px 10px;
    flex: 1;
    border: 1px solid #767474;
    box-sizing: border-box;
    border-radius: 5px;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;

    .address-input-placeholder {
      color: #767474;
      font-size: 14px;
      line-height: 19px;
      padding-right: 10px;
      flex: 1;
    }

    .address-input-text {
      display: flex;
      align-items: center;
      flex: 1;
      cursor: pointer;

      span {
        font-size: 12px;
        line-height: 16px;
        color: $blackColor;
        background: #d4f4f4;
        border-radius: 5px;
        padding: 3px 10px;
        margin-right: 10px;
      }

      p {
        font-size: 14px;
        line-height: 19px;
        color: $blackColor;
      }
    }
  }
}

.fast-cart-picker-address {
  position: absolute;
  top: 35px;
  left: 0;
  right: 0;
  display: none;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  z-index: 20;

  &.show {
    display: block;
  }
}

.fast-cart-picker-address__list-address {
  padding: 25px 20px;

  .list-address-box {
    width: 100%;
    height: 280px;
    overflow-y: auto;
  }

  .list-address-button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .list-address-button__add {
    font-size: 16px;
    line-height: 22px;
    color: #767474;
    border: 1px solid #767474;
    box-sizing: border-box;
    border-radius: 30px;
    padding: 5px 20px;
    background: $whiteColor;
    transition: 0.5s all;

    &:hover {
      transform: scale(1.1);
    }
  }

  .list-address-button__confirm {
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
    color: $whiteColor;
    border: 1px solid $baseColorLight;
    background: $baseColorLight;
    box-sizing: border-box;
    border-radius: 30px;
    padding: 5px 20px;
    transition: 0.5s all;
    margin-left: 20px;

    &:hover {
      transform: scale(1.1);
    }
  }
}

.fast-cart-picker-address__form-address {
  padding: 25px 20px;

  .form-control {
    font-size: 14px;
    line-height: 19px;
    color: $blackColor;
    border: 1px solid #767474;
    border-radius: 5px;
    padding: 10px 15px;
    margin-bottom: 20px;

    &::placeholder {
      color: #767474;
    }
  }

  .select-form {
    -webkit-appearance: radio;
    appearance: radio;
    color: $blackColor;

    option[value=''][disabled] {
      font-weight: bold;
    }
  }
}

.fast-cart-picker-address__form-address__type-btn {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  .check-box-save-address {
    display: flex;
    align-items: center;
    flex: 1;

    &.active {
      button {
        background: $baseColorLight;
        border: 1px solid $baseColorLight;
      }

      svg {
        display: block;
      }
    }

    button {
      width: 22px;
      height: 22px;
      border-radius: 22px;
      border: 1px solid #767474;
      background: $whiteColor;
    }

    svg {
      font-size: 9px;
      display: none;
      path {
        fill: $whiteColor;
      }
    }

    p {
      font-size: 14px;
      line-height: 19px;
      color: $blackColor;
      padding-left: 15px;
    }
  }

  .type-btn-address {
    display: flex;
    align-items: center;
    border: 1px solid #767474;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 5px 10px;
    margin-left: 10px;
    cursor: pointer;

    &:hover {
      border: 1px solid $baseColorLight;
    }

    p {
      padding-left: 5px;
      flex: 1;
      text-align: center;
      font-size: 12px;
      line-height: 16px;
      color: #767474;
    }

    &.active {
      background: $baseColorLight;
      border: 1px solid $baseColorLight;

      p {
        color: $whiteColor;
      }
    }
  }
}

.fast-cart-picker-address__form-address__add-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .switch-box-default-address {
    display: flex;
    align-items: center;

    p {
      font-size: 14px;
      line-height: 19px;
      color: $blackColor;
      padding-left: 20px;
    }
  }

  .add-btn-address {
    display: flex;
    align-items: center;
    background: $baseColorLight;
    border-radius: 30px;
    padding: 7px 20px;
    border: none;
    transition: 0.5s all;

    &:hover {
      transform: scale(1.1);
    }

    svg {
      font-size: 14px;
      path {
        fill: $whiteColor;
      }
    }

    span {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #ffffff;
      padding-left: 10px;
    }
  }
}

.fast-cart-picker-address-item {
  display: flex;
  align-items: center;

  &--border {
    border-top: 1px solid #bdbdbd;
    padding-top: 10px;
    margin-top: 10px;
  }

  &__checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 22px;
    border: 1px solid #767474;
    border-radius: 25px;
    cursor: pointer;

    span {
      width: 14px;
      height: 14px;
      border-radius: 14px;
    }

    &.active {
      border: 1px solid $orangeColor2;

      span {
        background: $orangeColor2;
      }
    }
  }

  &__content {
    flex: 1;
    padding-left: 20px;

    &__name {
      display: flex;
      align-items: center;
      margin-bottom: 5px;

      p {
        font-weight: bold;
        font-size: 14px;
        line-height: 19px;
        color: $blackColor;
      }

      span {
        font-size: 12px;
        line-height: 16px;
        color: $whiteColor;
        background: #27ae60;
        border-radius: 5px;
        padding: 3px 10px;
        margin-left: 10px;
      }
    }

    &__phone {
      font-size: 14px;
      line-height: 19px;
      color: $blackColor;
      margin-bottom: 5px;
    }

    &__address {
      display: flex;
      align-items: center;

      p {
        font-size: 14px;
        line-height: 19px;
        color: $blackColor;
      }

      span {
        font-size: 12px;
        line-height: 16px;
        color: #000000;
        background: #d4f4f4;
        border-radius: 5px;
        padding: 3px 5px;
        margin-right: 10px;
      }
    }
  }
}

.modal-cart-content__info__suggest-box {
  background: #ffe3c9;
  padding: 10px 20px;

  p {
    font-size: 14px;
    line-height: 19px;
    color: $blackColor;
  }

  .suggest-box__text--highlight {
    font-weight: bold;
    color: $baseColorLight;
  }

  .suggest-box__text--danger {
    font-size: 12px;
    line-height: 16px;
    color: #ec1c24;
  }
}

.modal-cart-content__info__payment {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;

  .payment-wrap {
    background: #006ebe;
    border-radius: 5px;
    padding: 8px 13px;
    display: flex;
    align-items: center;

    p {
      font-size: 12px;
      line-height: 16px;
      color: $whiteColor;
      padding-left: 10px;
    }

    span {
      display: block;
      font-weight: bold;
      font-size: 14px;
      line-height: 19px;
      text-transform: uppercase;
    }
  }
}

.modal-cart-content__info__voucher,
.modal-cart-content__info__point {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  border-top: 1px solid #bdbdbd;

  &__label {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: $blackColor;
  }

  &__picker {
    position: relative;
    display: flex;
    align-items: center;

    svg {
      font-size: 20px;
      margin-left: 10px;
      path {
        color: $blackColor;
      }
    }
  }

  .fast-cart-modal-picker-voucher {
    position: absolute;
    z-index: 10;
    top: 56px;
    right: 0;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 10px 15px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    display: none;

    &.show {
      display: block;
    }

    &__item-wrap {
      padding: 10px 0;
      border-bottom: 1px solid #bdbdbd;
    }

    &__item-wrap:last-child {
      padding: 10px 0;
      border-bottom: none;
    }
  }
}

.modal-cart-content__info__point__switch-btn {
  display: flex;
  align-items: center;

  p {
    font-size: 14px;
    line-height: 19px;
    color: $baseColorLight;
    padding-right: 20px;
  }
}

.modal-cart-content__info__cost {
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    font-size: 12px;
    line-height: 16px;
    color: $blackColor;
    padding-bottom: 5px;
  }
}

.modal-cart-content__info__note {
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    font-size: 14px;
    line-height: 19px;
    color: $blackColor;
  }

  input {
    font-size: 14px;
    line-height: 19px;
    color: $blackColor;
    width: 300px;
  }

  input::placeholder {
    font-size: 12px;
    line-height: 16px;
    color: #767474;
  }
}

.modal-cart-content__info__product {
  padding: 10px 20px;
}

.modal-cart-content__info__product__list-collapse {
  flex-wrap: nowrap;
  display: flex;
  overflow-x: auto;

  &::-webkit-scrollbar {
    height: 6px; /* height of horizontal scrollbar ← You're missing this */
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #ffffff;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #cdcaca;
    border-radius: 5px;
  }
}

.modal-cart-content__info__product__title-list {
  display: flex;
  align-items: center;
  padding: 20px 0;

  p {
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    text-transform: uppercase;
    color: $blackColor;
    padding-left: 20px;
  }

  .check-radio {
    svg {
      font-weight: bold;
      font-size: 14px;
    }
  }
}

.modal-cart-content__info__product__btn-more {
  display: flex;
  justify-content: center;

  button {
    display: flex;
    align-items: center;
    border: none;
    background: $whiteColor;

    svg {
      font-size: 14px;
      path {
        fill: $baseColorLight;
      }
    }
  }

  p {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: $baseColorLight;
    padding-right: 10px;
  }
}

.modal-cart-content__footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #ffffff;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
  padding: 15px 20px;
  display: flex;
  align-items: center;
  border-radius: 0 0 7px 7px;

  &__info {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .btn-choose {
    width: 100%;
    background-color: #f15b2e;
    font-weight: bold;
  }
}

.modal-cart-content__footer__btn-checkout {
  background: #f15b2e;
  border-radius: 30px;
  padding: 15px 40px;
  margin-left: 15px;
  transition: 0.5s all;
  border: none;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }

  a,
  p {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: $whiteColor;
    text-decoration: none;
  }
}

.modal-footer-info-total,
.modal-footer-info-point {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modal-footer-info-total__text {
  font-size: 14px;
  line-height: 19px;
  color: $blackColor;

  span {
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    color: $baseColorLight;
  }
}

.modal-footer-info-total__check-wrap {
  display: flex;
  align-items: center;

  &__btn-title {
    font-size: 14px;
    line-height: 19px;
    color: $blackColor;
    padding-left: 10px;
  }
}

.modal-footer-info-total__check-wrap__btn-check {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  border-radius: 22px;
  border: 1px solid #828282;
  cursor: pointer;

  svg {
    display: none;
  }

  &.checked {
    background: $orangeColor;
    border: 1px solid $orangeColor;

    svg {
      display: block;
      font-size: 9px;

      path {
        fill: $whiteColor;
      }
    }
  }
}

.modal-footer-info-point__text-point {
  font-size: 14px;
  line-height: 19px;
  color: #4f4f4f;
}

.modal-footer-info-point__switch-btn {
  display: flex;
  align-items: center;

  p {
    padding-left: 10px;
    font-size: 14px;
    line-height: 19px;
    color: $blackColor;
  }
}
