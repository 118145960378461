.prescription-screen {
  margin: auto;
  max-width: 1611px;
  padding: 25px 0;
  @media screen and (min-width: 1440px) {
    width: 100%;
  }
  .prescription-screen__breadcrumbs {
    p,
    a {
      font-size: 0.875rem;
      color: #008080;
    }
  }
  .prescription-screen__title {
    margin: 20px 0;
    p {
      color: #333333;
      font-size: 1.875rem;
      font-weight: bold;
    }
    .prescription-screen__title--blue {
      margin-top: 10px;
      color: #20abad;
      font-size: 3.125rem;
    }
  }
  .prescription-screen__main {
    display: flex;
    .prescription-screen__main__step {
      width: 37%;
      margin-right: 2%;
      background-color: #ffffff;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
      border-radius: 20px;
      padding: 0 23px 0px 38px;
      .prescription-screen__main__step__img {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 48px;
        margin-bottom: 45px;
      }
      .prescription-screen__main__step__detail {
        display: flex;
        color: #000000;
        margin: 10px 0 50px 0;
        align-items: center;
        img {
          margin-right: 31px;
        }
        p {
          font-size: 1rem;
          line-height: 22px;
          span {
            font-size: 1.25rem;
            font-weight: bold;
          }
        }
      }
    }
    .prescription-screen__main__form {
      width: 100%;
    }
  }
}
.prescription-screen__nav {
  #prescription-screen__nav-tabs {
    margin-bottom: 42px;

    .nav-item {
      width: 33%;
      border-bottom: 1px solid #d2cbcb;

      .nav-link {
        cursor: pointer;
        border: none;
        border-bottom: 5px solid #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        .icon {
          width: 24px;
          height: 24px;
          margin-right: 5px;
          background-repeat: no-repeat;
          background-position: center;
        }

        p {
          font-size: 1rem;
          color: #767474;
          font-weight: bold;
        }

        .nav-link__icon-got-prescription {
          background-image: url('../icons/OrderGuide/login.png');
        }

        .nav-link__icon-no-prescription {
          background-image: url('../icons/OrderGuide/purchase.png');
        }

        .nav-link__icon-no-invoice {
          background-image: url('../icons/OrderGuide/invoice.png');
        }

        &.active {
          border-bottom: 5px solid #20abad;

          // .icon {
          //   filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
          // }

          .nav-link__icon-got-prescription {
            background-image: url('../icons/OrderGuide/login-active.png');
          }

          .nav-link__icon-no-prescription {
            background-image: url('../icons/OrderGuide/purchase-active.png');
          }
          .nav-link__icon-no-invoice {
            background-image: url('../icons/OrderGuide/invoice-active.png');
          }

          p {
            color: #20abad;
          }
        }
      }
    }
  }
}

.prescription-screen__got-prescription-tab {
  > p {
    color: #000000;
    font-size: 1rem;
    line-height: 22px;
  }

  .prescription-screen__got-prescription-tab__form {
    .prescription-screen__got-prescription-tab__form__action {
      background-color: #fbfbfb;
      border: 1px dashed #767474;
      box-sizing: border-box;
      border-radius: 30px;
      display: flex;
      padding-top: 37px;
      padding-bottom: 20px;

      > div {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        p {
          color: #000000;
          font-weight: bold;
          margin: 15px 0;
        }
      }

      .prescription-screen__got-prescription-tab__typing {
        width: 46.7%;
        border-right: 1px solid #bdbdbd;

        .add-prescription {
          background-color: #20abad;
          border-radius: 20px;
          color: #ffffff;
          padding: 4px 22px;
          margin-bottom: 20px;
        }
      }

      .prescription-screen__got-prescription-tab__upload-image {
        width: 53.3%;

        .prescription-screen__got-prescription-tab__upload-image__btn-file {
          position: relative;
          overflow: hidden;
          text-align: center;

          p {
            font-size: 0.875rem;
            color: #767474;
            font-weight: normal;
            margin-bottom: 0;
          }

          .btn {
            background-color: #20abad;
            border-radius: 20px;
            color: #ffffff;
            padding: 4px 22px;
          }

          input[type='file'] {
            position: absolute;
            top: 0;
            right: 0;
            min-width: 100%;
            min-height: 100%;
            font-size: 100px;
            text-align: right;
            filter: alpha(opacity=0);
            opacity: 0;
            outline: none;
            background: white;
            cursor: inherit;
            display: block;
          }
        }
      }

      .prescription_img_upload {
        display: flex;
        align-items: center;
        justify-content: space-around;

        .img_upload-item {
          width: 30%;
          object-fit: contain;
          max-height: 200px;
        }
      }
    }

    .prescription-screen__got-prescription-tab__form-check {
      display: flex;
      flex-direction: row;
      align-items: center;

      .form-check-input {
        background-color: #ffffff;
        margin-top: 0;
        width: 20px;
        height: 20px;
        margin-right: 10px;

        &:checked {
          background-image: url('../icons/ic_checked.png');
          background-repeat: no-repeat;
          background-size: 14px 14px;
          border: 1px solid #fb800f;
        }
      }

      > label {
        margin-right: 20px;
        color: #000000;
      }
    }

    .btn-submit {
      text-align: right;
    }

    .prescription-screen__got-prescription-tab__form__btn-submit {
      background-color: #f15b2e;
      border-radius: 30px;
      padding: 15px 69px;
      color: #ffffff;
      text-transform: uppercase;
      font-size: 1rem;
      margin-left: auto;
    }

    .prescription-screen__got-prescription-tab__list {
      input {
        border-right: none;

        &:focus {
          border-color: #ced4da;
          box-shadow: none;
        }
      }

      button {
        &:focus {
          box-shadow: none;
        }
      }

      .btn-view-image {
        color: #20abad;
        border-bottom: 1px solid #ced4da;
        border-top: 1px solid #ced4da;
      }

      .btn-delete-img {
        border: 1px solid #ced4da;
        border-left: none;
      }
    }
  }

  .step-two {
    display: flex;
    flex-direction: column;
    height: 60%;
    justify-content: center;
    margin-left: 30px;
    /* Center horizontally within the .step-two container */
  }

  .step-icon {
    display: inline-block;
    /* Ensure the icon is centered with the text */
    vertical-align: middle;
    /* Align icon with the middle of the text */
  }

  .image-box img {
    max-width: 100%;
    /* Ensure the image fits within its container */
    height: auto;
    /* Maintain aspect ratio of the image */
  }
  .step-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* Two equal columns */
    align-items: center;
    gap: 20px;
    padding: 0px;
  }
  .text-first-login {
    margin-left: 30px !important;
  }

  .step-box {
    display: flex;
    align-items: center;
    /* Align icon and text vertically in the center */
  }

  .step-icon {
    /* Adjust the size to match the text */
    color: #20abad;
    margin-right: 7px;
    /* Space between the icon and the text */
    vertical-align: middle;
    width: 25px;
    /* Align icon vertically in the middle of the text */
  }

  .step-content {
    color: #333;
  }

  .step-content strong {
    color: #20abad;
  }

  .step-content p {
    margin: 0;
    font-size: 16px;
  }

  .image-box {
    display: flex;
    justify-content: flex-end;
  }

  .image-box img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
  }
}

.prescription-screen-modal {
  .modal {
    &.show {
      .modal-dialog {
        transform: none;
      }
    }
    &.fade {
      .modal-dialog {
        transition: transform 0.5s ease-out;
      }
    }
  }
  .modal-dialog {
    margin: 0 0;
    float: right;
    height: 100%;
    width: 900px;
    background-color: #ffffff;
  }

  .prescription-screen-modal__content {
    // height: 100vh;
    .prescription-screen-modal__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 20px;
      background: #20abad;
      h3 {
        font-weight: bold;
        font-size: 1.375rem;
        line-height: 28px;
        color: #ffffff;
      }
      button {
        font-size: 24px;
        color: #ffffff;
        border: none;
        background: #20abad;
      }
    }
    .prescription-screen-modal__content-top-left {
      border-right: 1px solid #a49c9c;
    }
    .prescription-screen-modal__content-top {
      padding: 30px 20px 10px 20px;
    }
    .prescription-date_value {
      margin-left: 10px;
    }
    .font-weight-bold {
      font-weight: bold;
    }
    // .prescription-screen-modal__content-top-left,
    // .prescription-screen-modal__content-top-right {
    //   padding: 0 20px 10px 20px;
    // }
    .prescription-screen-modal__body {
      overflow-y: auto;
      height: 85%;
      padding: 22px 30px;
      .prescription-screen-modal__body__search {
        margin-top: 8px;
        button {
          outline: 0;
          border: none;
          background-color: #e5e5e5;
          border-top-left-radius: 30px;
          border-bottom-left-radius: 30px;
          height: 47px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding-right: 0;
          &:focus {
            box-shadow: none;
          }
        }
        input {
          border: none;
          background-color: #e5e5e5;
          border-top-right-radius: 30px;
          border-bottom-right-radius: 30px;
          &:focus {
            box-shadow: none;
          }
        }
      }
      .prescription-screen-modal__body__item {
        display: flex;
        align-items: center;
        color: #000000;
        font-size: 0.875rem;
        justify-content: space-between;
        border-bottom: 1px solid #bdbdbd;
        height: 60px;
        padding-left: 19px;
        p {
          width: 80%;
        }
        button {
          background-color: #20abad;
          border-radius: 20px;
          color: #ffffff;
        }
      }
      .prescription-screen-modal__body__list {
        margin-top: 30px;
        .prescription-screen-modal__body__list__title {
          margin-bottom: 22px;
          color: #000000;
          text-transform: uppercase;
          font-size: 1rem;
          font-weight: bold;
        }
        .prescription-screen-modal__body__list__item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 8px;
          font-weight: bold;
          color: #000000;
          p {
            width: 80%;
          }
          button {
            border-radius: 20px;
            border: 1px solid #000000;
          }
        }
      }
    }
    .prescription-screen-modal__footer {
      .modal-cart-content__footer__btn-cancel {
        border: 1px solid #000000;
        box-sizing: border-box;
        border-radius: 30px;
        padding: 15px 40px;
        transition: 0.5s all;
      }

      .modal-cart-content__footer__btn-checkout {
        color: #ffffff;
      }
    }
    .prescription-screen-image-modal__body {
      height: 100%;
      padding: 0 33px;
      .prescription-screen-image-modal__body__img {
        height: 465px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 50px 0;
        img {
          width: 425px;
          max-height: 465px;
          border: 1px solid #767474;
          padding: 16px;
          border-radius: 10px;
          object-fit: contain;
        }
      }
      .prescription-screen-image-modal__body__list_img {
        position: relative;
        .prescription-screen-image-modal__body__list_img__title {
          color: #131313;
          font-weight: bold;
          font-size: 1rem;
          line-height: 22px;
          margin-bottom: 20px;
        }
        .list-slide {
          display: flex;
          .slider-item {
            margin-right: 10px;
            img {
              width: 86px;
              height: 110px;
              object-fit: cover;
            }
          }
        }
        .slick-slider {
          width: 100%;
        }
        .slider-item {
          img {
            padding: 10px;
            // width: 200px;
            &.active {
              border: 1px solid #20abad;
              box-sizing: border-box;
              border-radius: 10px;
            }
          }
        }

        button {
          cursor: pointer;
          border: none;
          background-color: transparent;
          outline: 0;
          position: absolute;
          top: 50%;
        }
        .prescription-screen__btn-carousel-left {
          left: 0;
        }
        .prescription-screen__btn-carousel-right {
          right: 0;
        }
      }
    }
  }
  .prescription-screen-list-drug-modal__body {
    padding: 30px 20px 10px 20px;

    .prescription-screen-list__title {
      font-size: 16px;
      font-weight: bold;
      text-transform: uppercase;
    }
    .prescription-screen-list__item {
      font-size: 16px;
      font-weight: bold;
      overflow-wrap: break-word;
    }
  }
  .prescription-screen_result {
    max-height: 200px;
    overflow-x: auto;
    &::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #ffffff;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #cdcaca;
      border-radius: 5px;
    }
  }
}
