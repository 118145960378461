@import '../../assets/scss/var.scss';
.loader-container {
  width: 100%;
  height: 100%;
  display: flex;
  top: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  position: absolute;
  background: #00000036;
  z-index: 999;
}

.spinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: $baseColor transparent $baseColor transparent;
  border-radius: 50%;
  animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
