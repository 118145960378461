@import 'var';

.modal-auth-input {
  .modal {
    background: rgba(0, 0, 0, 0.5);
  }

  .modal.fade .modal-dialog {
    transition: transform 0.5s ease-out;
    transform: translate(200px, 0);
  }

  .modal.show .modal-dialog {
    transform: none;
  }

  .modal-dialog {
    margin: 0 0;
    float: right;
    height: 100%;
    width: 800px;
  }
}

.modal-auth-input-content {
  height: 100vh;
  background: $whiteColor;
}

.modal-auth-input-content__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;

  h3 {
    font-weight: bold;
    font-size: 22px;
    line-height: 28px;
    color: $whiteColor;
  }

  button {
    svg {
      font-size: 24px;
      border: none;
      background: none;
      path {
        fill: #767474;
      }
    }
  }
}

.modal-auth-input-content__body {
  padding: 10px 60px 30px 80px;
  overflow-y: auto;
  height: 100%;

  .form-control {
    font-size: 12px;
    line-height: 16px;
    color: #767474;
    margin-bottom: 20px;
  }
}

.auth-box-wrap {
  h2 {
    font-weight: 700;
    font-size: 30px;
    line-height: 41px;
    color: #000000;
    margin-bottom: 12px;
  }

  .auth-sub-title {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #767474;
    margin-bottom: 20px;

    a {
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      color: #20abad;
      cursor: pointer;
    }
  }

  .auth-box-name {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #3f3b3b;
      white-space: pre-line;
    }

    div {
      border-top: 1px solid #d2cbcb;
      flex: 1;
      margin-left: 20px;
    }
  }

  .auth-box-text-confirm {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #3f3b3b;
    padding-top: 20px;
    text-align: center;

    a {
      font-size: 16px;
      line-height: 22px;
      color: #20abad;
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .auth-box-text-time {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #f15b2e;
    text-align: center;
  }
}

.auth-box-result {
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    font-weight: 700;
    font-size: 30px;
    line-height: 32px;
    text-align: center;
    color: #000000;
    padding-top: 30px;
  }

  p {
    font-weight: 400;
    font-size: 14px;
    color: #181725;
    white-space: pre-line;
    padding-top: 50px;
  }
}

.auth-box-form {
  label {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #4f4f4f;
    margin-bottom: 8px;
  }

  &__item {
    .auth-box-form__input {
      display: flex;
      align-items: center;
      border: 1px solid #20abad;
      box-sizing: border-box;
      border-radius: 5px;
      padding: 15px 15px;

      input {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #3f3b3b;
        flex: 1;
        border: none;

        &:focus {
          outline: none;
        }

        &::placeholder {
          color: #767474;
        }
      }

      &__icon {
        display: flex;
        width: 30px;
      }

      button {
        border: none;
        background: none;

        &:focus {
          outline: none;
        }
      }
    }
  }

  &__btn-submit {
    display: flex;
    justify-content: center;
    padding-top: 50px;

    button {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #ffffff;
      background: #f15b2e;
      border-radius: 30px;
      border: none;
      padding: 15px 15px;
      width: 50%;
      transition: 0.5s all;

      &:focus {
        outline: none;
      }

      &:hover {
        transform: scale(1.1);
      }
    }
  }

  &__input-otp {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 0;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type='number'] {
      -moz-appearance: textfield;
    }

    input {
      font-weight: 600;
      font-size: 36px;
      line-height: 22px;
      color: #3f3b3b;
      padding: 15px 20px;
      background: #f5f5f5;
      border: 1px solid #c4c4c4;
      box-sizing: border-box;
      border-radius: 5px;
      width: 70px;
      text-align: center;
      margin: 0 10px;

      &:focus {
        outline: none;
        border: 1px solid #20abad;
        background: $whiteColor;
      }
    }
  }

  p {
    color: red;
    height: 20px;
    margin: 12px 0;
  }
}

.auth-box-form__option {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__check {
    display: flex;
    align-items: center;

    .check-radio {
      margin-top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 22px;
      height: 22px;
      border-radius: 22px;
      border: 1px solid #000000;
      cursor: pointer;

      svg {
        font-size: 10px;
        path {
          fill: #ffffff;
        }
      }

      &:hover,
      &.active {
        background: #20abad;
        border: 1px solid #20abad;
      }
    }

    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #3f3b3b;
      padding-left: 15px;

      a {
        font-size: 16px;
        line-height: 22px;
        color: #20abad;
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  &__reset {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #20abad;
    text-decoration: underline;
    border: none;
    background: none;
    padding: 0;
    cursor: pointer;
  }
}

.auth-forgot-pass-box {
}

.auth-login-box__btn-login {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 0;

  button {
    border: none;
    background: none;
    padding: 0;
    transition: 0.5s all;
    margin: 5px;

    &:hover {
      transform: scale(1.1);
    }
  }
}
.select-register {
  border: none;
  width: 100%;
  font-size: 14px;
  &:focus {
    border: none;
    outline-width: 0;
  }
}
